<template>
    <div class="coursePage clearfix">
        <div class="course_l fl">
            <ul class="course_l_ul">
                <li :class="istrue==1?'active_li':''" @click="oBox(1)">
                    <i class="iconfonts icons-kechengguanlix"></i>
                    <span>课程信息</span>
                </li>
                <li :class="istrue==2?'active_li':''" @click="oBox(2)">
                    <i class="iconfonts icons-xitongguanli" style="margin-left:2px"></i>
                    <span>个人中心</span>
                </li>
            </ul>
        </div>
        <div class="course_r fl">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            istrue:1
        }
    },
    methods: {
        oBox(val){
            if(val==1){
                this.istrue = val
                this.$router.push({name:'Courseinformation'})
            }else if(val==2){
                this.istrue = val
                this.$router.push({name:'Personalcenter'})
            }
        },
        getPath(){
            if(this.$route.name=="Courseinformation"){
                this.istrue = 1
            }else if(this.$route.name=="Personalcenter"){
                this.istrue = 2
            }
        }
    },
    mounted() {
        this.getPath()
    },
    watch:{
        $route(to,from){
            this.getPath()
        }
    }
}
</script>
<style scoped>
@import './../../assets/css/teacher/coursePage.css';
</style>